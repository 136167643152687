import React, { useState, useContext, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { useNavigate, useParams, Link } from "react-router-dom";

import {
  Box,
  Icon,
  Avatar,
  Container,
  Flex,
  Text,
  SimpleGrid,
  Grid,
  GridItem,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Switch,
} from "@chakra-ui/react";
import { FiSettings, FiEye, FiEyeOff } from "react-icons/fi";
import { UserContext } from "../../contexts/User";
import Select, { components } from "react-select";

export default function SettingsExpert() {
  const { updateExpertApi, getExpertInfoApi } = useContext(UserContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const myUser = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [storedPassword, setStoredPassword] = useState("**********");

  const [isEditing, setIsEditing] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [fullName, setFullName] = useState("");
  const [nationality, setNationality] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [street_address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [permissions, setPermission] = useState([]);
  const [expert_test, setExpertTest] = useState("");
  const [status, setStatus] = useState("");
  const [editor, setEditor] = useState();
  const [data, setData] = useState({});

  const handleInputStateChange = (selectedOption) => {
    setState(selectedOption.value); // Atualiza o estado 'state' com a opção selecionada
  };

  const handleInputPermissionChange = (selectedOptions) => {
    if (selectedOptions) {
      let newPermissions = [selectedOptions.value];
      if (editor === 1 && !newPermissions.includes(4)) {
        newPermissions.push(4);
      }
      setPermission(newPermissions);
    } else {
      setPermission(editor === 1 ? [4] : []);
    }
  };
  

  const handleInputEditorChange = () => {
    setEditor((prevEditor) => {
      const newEditor = prevEditor === 1 ? 0 : 1;
      setPermission((prevPermissions) => {
        if (newEditor === 1) {
          if (!prevPermissions.includes(4)) {
            return [...prevPermissions, 4];
          }
        } else {
          return prevPermissions.filter((permission) => permission !== 4);
        }
        return prevPermissions;
      });
      return newEditor;
    });
  };
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
  const handleToggleStatus = () => {
    setStatus((prevStatus) => (prevStatus === 1 ? 0 : 1));
  };
  const handleToggleExpertTest = () => {
    setExpertTest((prevExpertTest) => (prevExpertTest === 1 ? 0 : 1));
  };
  async function showUserDetails() {
    try {
      const response = await getExpertInfoApi(id);
      setUser(response?.data?.[0]);
      setFullName(response?.data?.[0]?.name);
      setNationality(response?.data?.[0]?.nationality);
      setPhone(
        response?.data?.[0]?.phone == null ? "" : response?.data?.[0].phone
      );
      setCpf(response?.data?.[0]?.cpf);
      setEmail(response?.data?.[0]?.email);
      setZipcode(
        response?.data?.[0]?.zipcode == null ? "" : response?.data?.[0]?.zipcode
      );
      setAddress(response?.data?.[0]?.street_address);
      setCity(response?.data?.[0]?.city);
      setState(response?.data?.[0]?.state);
      setCountry(response?.data?.[0]?.country);
      const permissionsExpert = response?.data?.[0]?.permissions;
      const rolesPermissions = permissionsExpert.map(
        (item) => item.expert_role_id
      );
      setPermission(rolesPermissions);
      setExpertTest(response?.data?.[0]?.expert_test);
      setStatus(response?.data?.[0]?.status);
      console.log("TESTE", response?.data?.[0]?.expert_test);
      if (rolesPermissions.includes(4)) {
        setEditor(1);
      } else {
        setEditor(0);
      }
      console.log("PERMISSIONS", rolesPermissions);
      console.log(response?.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    showUserDetails();
  }, [id]);

  const updateExpert = async () => {
    if (newPassword != null) {
      // Verifique e salve a nova senha se os campos de senha estiverem preenchidos
      if (newPassword && confirmPassword) {
        if (newPassword === confirmPassword) {
          setStoredPassword(newPassword);
          setNewPassword("");
          setConfirmPassword("");
        } else {
          alert("As senhas não coincidem!");
          return;
        }
      }

      setIsEditing(false); // Desative o modo de edição
      setShowChangePassword(false); // Desative o modo de alterar senha
    }

    const data = {
      name: fullName,
      email: email,
      cpf: cpf,
      nationality: nationality,
      phone: phone,
      zipcode: zipcode,
      street_address: street_address,
      city: city,
      password: newPassword,
      state: state,
      country: country,
      permissions: permissions,
      expert_test: expert_test,
      status: status
    };

    console.log(data);

    try {
      const updateExpert = await updateExpertApi(data, id);
      if (updateExpert.status === 200) {
        toast({
          title: "Usuário Atualizado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      showUserDetails();
      setIsEditing(false);
    } catch (error) {
      toast({
        title: "Deu algo errado, verifique os campos e tente novamente",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Flex
        backgroundColor={"white"}
        borderRadius={"8px 8px"}
        boxShadow={"0px 8px 16px -10px"}
        marginLeft={"250px"}
        marginRight={"50px"}
        marginTop={"100px"}
      >
        <Box w={"100%"}>
          <Box
            p={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px"}
            borderColor={"#DBDBDB"}
          >
            <Text> Dados</Text>
            <Button
              borderRadius={"100px"}
              background={"white"}
              border={"1px"}
              borderColor={"#DBDBDB"}
              color={"#505050"}
              fontWeight={400}
              fontSize={"14px"}
              onClick={toggleEdit}
            >
              Editar dados
            </Button>
          </Box>

          <Box p={2}>
            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                Nome completo
              </Text>
              {isEditing ? (
                <Input
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              ) : (
                <Text>{fullName}</Text>
              )}
            </Box>
            {/**  <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                Imagem do perfil
              </Text>
              {isEditing ? (
                <>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setProfileImageFile(e.target.files[0])}
                  />
                  {profileImageFile && <Text>{profileImageFile.name}</Text>}
                </>
              ) : (
                <Text>{profileImageFile}</Text>
            </Box>
              )} ***/}

            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                Nacionalidade
              </Text>
              {isEditing ? (
                <Input
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                />
              ) : (
                <Text>{nationality}</Text>
              )}
            </Box>
            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                Telefone
              </Text>
              {isEditing ? (
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              ) : (
                <Text>{phone}</Text>
              )}
            </Box>
            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                CPF
              </Text>
              {isEditing ? (
                <Input value={cpf} onChange={(e) => setCpf(e.target.value)} />
              ) : (
                <Text>{cpf}</Text>
              )}
            </Box>
            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                Email
              </Text>
              {isEditing ? (
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <Text>{email}</Text>
              )}
            </Box>

            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                CEP
              </Text>
              {isEditing ? (
                <Input
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              ) : (
                <Text>{zipcode}</Text>
              )}
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                  Endereço
                </Text>
                {isEditing ? (
                  <Input
                    value={street_address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                ) : (
                  <Text>{street_address}</Text>
                )}
              </Box>
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                  Cidade
                </Text>
                {isEditing ? (
                  <Input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                ) : (
                  <Text>{city}</Text>
                )}
              </Box>
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                  Estado
                </Text>
                {isEditing ? (
                  <Select
                    placeholder="Estado"
                    defaultValue={{
                      value: state,
                      label: state,
                    }}
                    // Define o valor selecionado com base no estado 'state'
                    options={[
                      { value: "ac", label: "Acre" },
                      { value: "al", label: "Alagoas" },
                      { value: "am", label: "Amazonas" },
                      { value: "ap", label: "Amapá" },
                      { value: "ba", label: "Bahia" },
                      { value: "ce", label: "Ceará" },
                      { value: "df", label: "Distrito Federal" },
                      { value: "es", label: "Espírito Santo" },
                      { value: "go", label: "Goiás" },
                      { value: "ma", label: "Maranhão" },
                      { value: "mt", label: "Mato Grosso" },
                      { value: "ms", label: "Mato Grosso do Sul" },
                      { value: "mg", label: "Minas Gerais" },
                      { value: "pa", label: "Pará" },
                      { value: "pb", label: "Paraíba" },
                      { value: "pr", label: "Paraná" },
                      { value: "pe", label: "Pernambuco" },
                      { value: "pi", label: "Piauí" },
                      { value: "rj", label: "Rio de Janeiro" },
                      { value: "rn", label: "Rio Grande do Norte" },
                      { value: "ro", label: "Rondônia" },
                      { value: "rs", label: "Rio Grande do Sul" },
                      { value: "rr", label: "Roraima" },
                      { value: "sc", label: "Santa Catarina" },
                      { value: "se", label: "Sergipe" },
                      { value: "sp", label: "São Paulo" },
                      { value: "to", label: "Tocantins" },
                    ]}
                    onChange={handleInputStateChange}
                  />
                ) : (
                  <Text>{state}</Text>
                )}
              </Box>
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                  País
                </Text>
                {isEditing ? (
                  <Input
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                ) : (
                  <Text>{country}</Text>
                )}
              </Box>
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                  Tipo de usuário
                </Text>
                {isEditing && myUser.role_id == 1 ? (
                  <>
                    <Select
                      defaultValue={permissions.map((permission) => {
                        return {
                          value: permission,
                          label:
                            permission === 1
                              ? "Tradutor"
                              : permission === 2
                              ? "Revisor"
                              : permission === 3
                              ? "Editor"
                              : "",
                        };
                      })}
                      onChange={handleInputPermissionChange}
                      options={[
                        { value: 1, label: "Tradutor" },
                        { value: 2, label: "Revisor" },
                        { value: 3, label: "Editor" },
                      ]}
                    ></Select>
                  </>
                ) : (
                  <Text>
                    {permissions.length > 0
                      ? permissions.map((permission) => {
                          switch (permission) {
                            case 1:
                              return "Tradutor";
                            case 2:
                              return "Revisor";
                            case 3:
                              return "Editor";
                            default:
                              return "";
                          }
                        })
                      : "Sem permissões"}
                  </Text>
                )}
              </Box>
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                Editor das normas da revista ?
              </Text>
              {isEditing && myUser.role_id == 1 ? (
                <Switch
                  colorScheme="green"
                  isChecked={editor === 1}
                  onChange={handleInputEditorChange}
                >
                </Switch>
              ) : (
                <Switch
                  colorScheme="green"
                  isChecked={editor === 1}
                >
                </Switch>
              )}
              </Box>
              <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
                <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                  Expert em fase de teste ?
                </Text>
                {isEditing && myUser.role_id == 1 ? (
                <Switch
                  colorScheme="green"
                  isChecked={expert_test === 1}
                  onChange={handleToggleExpertTest}
                >
                </Switch>
              ) : (
                <Switch
                  colorScheme="green"
                  isChecked={expert_test === 1}
                >
                </Switch>
              )}
              </Box>
              <Box m={2} w={"30%"} marginRight={"20px"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                 Usuário Ativo?
              </Text>
              {isEditing && myUser.role_id == 1 ? (
                <Switch
                  colorScheme="green"
                  isChecked={status === 1}
                  onChange={handleToggleStatus}
                >
                </Switch>
              ) : (
                <Switch
                  colorScheme="green"
                  isChecked={status === 1}
                >
                </Switch>
              )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>

      <Flex
        backgroundColor={"white"}
        borderRadius={"8px 8px"}
        boxShadow={"0px 8px 16px -10px"}
        marginLeft={"250px"}
        marginRight={"50px"}
        marginTop={"15px"}
      >
        <Box w={"100%"}>
          <Box
            p={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px"}
            borderColor={"#DBDBDB"}
          >
            <Text> Alterar senha</Text>
            <Button
              borderRadius={"100px"}
              background={"white"}
              border={"1px"}
              borderColor={"#DBDBDB"}
              color={"#505050"}
              fontWeight={400}
              fontSize={"14px"}
              onClick={() => setShowChangePassword(!showChangePassword)}
            >
              Alterar senha
            </Button>
          </Box>

          <Box p={2}>
            <Box m={2} borderBottom={"1px"} borderColor={"#DBDBDB"}>
              <Text fontSize={"12px"} fontWeight={300} color={"#05070B99"}>
                Senha cadastrada
              </Text>
              <Text>{storedPassword.replace(/./g, "*")}</Text>
            </Box>
            {showChangePassword && (
              <>
                <InputGroup m={2}>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Nova senha"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <InputRightElement
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FiEye /> : <FiEyeOff />}
                  </InputRightElement>
                </InputGroup>
                <InputGroup m={2}>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirmar nova senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputRightElement
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FiEye /> : <FiEyeOff />}
                  </InputRightElement>
                </InputGroup>
              </>
            )}
          </Box>
        </Box>
      </Flex>
      <Box
        marginLeft={"300px"}
        marginRight={"50px"}
        marginTop={"15px"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Button>
          {myUser.role_id == 1 ? (
            <Link to="/usuarios">Voltar</Link>
          ) : (
            <Link to={`/home/${myUser.name}`}>Voltar</Link>
          )}
        </Button>
        <Button colorScheme="green" onClick={() => updateExpert()}>
          Salvar
        </Button>
      </Box>
    </>
  );
}
