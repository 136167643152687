import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { PaymentContext } from "../../contexts/Payments";
import DatePicker from "react-datepicker";
import api from "../../services/api";
import { ptBR } from "date-fns/locale";
import { format, parseISO } from "date-fns";
import {
  MdEditSquare,
  MdRemoveRedEye,
  MdOutlineTextSnippet,
} from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import { FaFileExcel } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";

export default function Payments() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listPaymentsApi } = useContext(PaymentContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [payments, setPayments] = useState([]);
  const [paymentPreview, setPaymentPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const token = localStorage.getItem("token");
  const currentYear = new Date().getFullYear();
  const selectDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const toast = useToast();
  const today = new Date().toISOString().split("T")[0];
  async function listPayments(page) {
    try {
      const paymentsApi = await listPaymentsApi(page);
      setPayments(paymentsApi.data?.[0]?.data || []);
      setLastPage(paymentsApi.data?.[0]?.last_page || 1);
      setCurrentPage(page);
    } catch (error) {
      console.log(error);
    }
  }

  function handlePaymentPage(index) {
    if (user.role_id === 1) {
      navigate(`comprovante/${index.id}`);
    }
  }

  function previewPayment(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setPaymentPreview(index);

    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }

  useEffect(() => {
    listPayments(currentPage);
  }, [currentPage]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  const handleExportToExcel  = async ()=> {
    if (!startDate || !endDate) {
      toast({
        title: "Por favor, selecione uma data.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const params = {
        start_date: startDate, 
        end_date: endDate,
      };
  
      const response = await api.get(`orders/payments/export/experts/`, {
        params,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }); 
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payments_experts.xslx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast({
          title: "Não há Serviços Criados nesse Período",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      console.log(error);
    }
    
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <>
      <Navbar />

      <Container position={"absolute"} top={120} ml={270} minWidth={"78vw"}>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          padding={5}
        >
          <Flex alignItems="center">
            <Text fontSize={"24px"} fontWeight={"600"}>
              Pagamentos
            </Text>
            <Spacer />
          </Flex>
        </Box>
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          padding={"2rem"}
          marginTop={"1rem"}
        >
          <Text fontSize={"20px"} fontWeight={"400"}>
            Busca por Data
          </Text>

          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
            flexWrap={"wrap"}
            marginTop={"1rem"}
          >
             <Box width={"14rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Data
                </Text>
                <DatePicker
                  selected={startDate}
                  onChange={selectDates}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  locale={ptBR}
                  placeholderText="Selecione o período"
                  dateFormat="yyyy/MM/dd"
                  className="custom-date-input"
                  showYearDropdown
                  monthsShown={2}
                  minDate={new Date(2023, 0, 1)}
                  maxDate= {today}
                  dropdownMode="select"
                />
              </Box>
            <Box>
              {" "}
              <Button
                marginTop={"1rem"}
                colorScheme="green"
                fontSize={"14px"}
                leftIcon={<FaFileExcel />}
                onClick={handleExportToExcel}
              >
                Extrair Excel
              </Button>
            </Box>
          </Box>
        </Box>

        {/*<Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Expert</Th>
                  <Th>Status</Th>
                  <Th>Data de Pagamento</Th>
                  <Th>Valor final</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"300"}>
                {payments.map((index) => (
                  <Tr key={index.id}>
                    <Td>{index.order_id}</Td>
                    <Td>{index.user_name}</Td>
                    <Td>{index.paid === 0 ? "Não recebido" : "Recebido"}</Td>
                    <Td>
                      {index.paid === 0 ? "Não recebido" : index.updated_at}
                    </Td>
                    <Td>{index.value_to_receipt}</Td>
                    <Td>
                      <Box display={"inline-flex"} fontSize={"16px"}>
                        <Box
                          cursor={"pointer"}
                          color={"#115EFA"}
                          onClick={() => handlePaymentPage(index)}
                        >
                          <MdEditSquare />
                        </Box>
                        <Box ml={2} color={"#00FF94"}>
                          <MdRemoveRedEye
                            onClick={() => previewPayment(index)}
                            cursor={"pointer"}
                          />
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box> */}

        {/* <Box>
          <Button
            colorScheme="green"
            onClick={() => goToPreviousPage(currentPage - 1)}
            disabled={currentPage === 1}
            fontSize={"12px"}
          >
            &laquo; Anterior
          </Button>
          <Button
            colorScheme="green"
            ml={2}
            onClick={() => goToNextPage(currentPage + 1)}
            disabled={currentPage === lastPage}
            fontSize={"12px"}
          >
            Próxima &raquo;
          </Button>
        </Box> */}
      </Container>
    </>
  );
}
