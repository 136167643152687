import React, { useState, useContext, useEffect } from "react";
import {
  useToast,
  Box,
  Text,
  Tabs,
  Tab,
  TabList,
  AspectRatio,
  Badge,
  Tooltip
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";

export default function VideoProvatis() {
  const toast = useToast();
  const {
    listVideosProlabApi,
    listVideosSubcategoryApi
  } = useContext(ProlabContext);
  const [categoryName, setCategoryName] = useState("");
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [categoryId, setCategoryId] = useState(4);
  const { learningCategoriesApi } = useContext(OrderContext);
  const [videos, setVideos] = useState("");;
  const [selectedSubcategory, setSelectedSubcategory] = useState();

  function limitToTwoWords(text) {
    if (text.length > 16) {
      const islimitWords = true;
      return {text: text.slice(0, 16) + " ...", islimitWords}
    }
    return text;
  }

  async function listVideo() {
    try {
      const response = await listVideosProlabApi(categoryId);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi() {
    try {
      const response = await learningCategoriesApi();
      const categoriesOrdered = [...response.data?.[0]]?.sort((a, b) => {
        const nameA = a.category?.toLowerCase() || ''; 
        const nameB = b.category?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      console.log(response.data?.[0]);
      setCategories(categoriesOrdered);
    } catch (error) {
      console.log(error);
    }
  }

  async function listVideoCategory(id) {
    try {
      const response = await listVideosProlabApi(id);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function listVideosSubcategory(id, sub) {
    try {
      const response = await listVideosSubcategoryApi(id, sub);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  const handleCategoryClick = async (category) => {
    const newSelectedCategory = category.id === selectedCategory ? null : category.id;
    
    setSelectedCategory(newSelectedCategory);
    setCategoryId(category.id);
    setCategoryName(category.category);
    setSelectedSubcategory(null);
    listVideoCategory(category.id);

    try {
      const response = await listVideosProlabApi(category.id);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubcategoryClick = async (sub) => {
    setSelectedSubcategory(sub.id);
    listVideosSubcategory(categoryId, sub.id);
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listVideo();
  }, []);
  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>

              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((category) => {
                  const { text, islimitWords } = limitToTwoWords(
                    category.category
                  );
                  const hasSubcategories =
                    category.subcategories_continuing_learning?.length > 0;

                  return (
                    <Box key={category.id}>
                      {islimitWords ? (
                        <Tooltip
                          label={category.category}
                          fontSize="sm"
                          hasArrow
                          bg="white"
                          color="black"
                        >
                          <Tab
                            mt={"1.5px"}
                            fontSize={"14px"}
                            _hover={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                            _selected={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                            onClick={() => handleCategoryClick(category)}
                          >
                            {text}
                          </Tab>
                        </Tooltip>
                      ) : (
                        <Tab
                          mt={"1.5px"}
                          fontSize={"14px"}
                          _hover={{ backgroundColor: "black", color: "white" }}
                          _selected={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                          onClick={() => handleCategoryClick(category)}
                        >
                          {category.category}
                        </Tab>
                      )}

                      {hasSubcategories && selectedCategory === category.id && (
                        <Box ml={4} mt={2}>
                          {category.subcategories_continuing_learning.map(
                            (sub) => (
                              <Tab
                                key={sub.id}
                                fontSize={"12px"}
                                _hover={{
                                  backgroundColor: "gray.600",
                                  color: "white",
                                }}
                                onClick={() => handleSubcategoryClick(sub)}
                              >
                                {sub.name}
                              </Tab>
                            )
                          )}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </TabList>
            </Box>
          </Tabs>

          <Box marginTop={"110px"}>
            {videos &&
              videos.map((index) => (
                <Box
                  key={index.id}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  w={"75%"}
                  ml={"250px"}
                  mt={"20px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Vídeo Provatis</Text>
                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    ></Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}
                    </Text>
                    <Box ml={2}>
                      {index?.tags?.map((tag, tagIndex) => (
                        <Badge
                          key={tagIndex}
                          display="inline-block"
                          bg="green.200"
                          color="green.800"
                          borderRadius="full"
                          p={2}
                          mr={2}
                          position="relative"
                        >
                          {tag.category_name}
                        </Badge>
                      ))}
                    </Box>
                  </Box>

                  <Box ml={1} mt={4}>
                    {index?.subs?.map((sub) => (
                      <Box
                        key={sub.id}
                        display="inline-block"
                        bg="gray.200"
                        color="gray.800"
                        borderRadius="full"
                        fontSize="xs"
                        p={1}
                        mr={2}
                        position="relative"
                      >
                        {sub.category_name}
                      </Box>
                    ))}
                  </Box>
                  <Box mt={"20px"} mb={"15px"}>
                    <Text fontSize={"24px"}>{index.title}</Text>
                  </Box>
                  <Box>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: index.description,
                      }}
                    />
                  </Box>
                  <AspectRatio ratio={16 / 9}>
                    <iframe src={index.video} allowFullScreen />
                  </AspectRatio>
                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
