import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
  useToast,
  Box,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  Tabs,
  Tab,
  TabList,
  AspectRatio,
  Select,
  Tag,
  TagLabel,
  Tooltip
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";
import { Badge, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSave,
} from "react-icons/ai";
import ReactSelect from "react-select";

export default function VideoProvatis() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const {
    listVideosProlabApi,
    createVideoProlabApi,
    updateVideoApi,
    deleteVideoApi,
    deleteSecondaryCategoryVideoApi,
    listVideosSubcategoryApi
  } = useContext(ProlabContext);
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [editingVideoId, setEditingVideoId] = useState(null);
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categoryId, setCategoryId] = useState(4);
  const [tempCategory, setTempCategory] = useState("");
  const [tempVideo, setTempVideo] = useState("");
  const { learningCategoriesApi } = useContext(OrderContext);
  const [videos, setVideos] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [tempLink, setTempLink] = useState("");
  const [selectedCategories, setSelectedCategories] = useState();
  const [options, setOptions] = useState([]);
  const [selectedSecondaryCategories, setSelectedSecondaryCategories] =
    useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Ajuste o valor conforme necessário
    }),
  };

  function limitToTwoWords(text) {
    if (text.length > 16) {
      const islimitWords = true;
      return {text: text.slice(0, 16) + " ...", islimitWords}
    }
    return text;
  }

  const handleCategoryUpdate = (event) => {
    const categoryId = event.target.value ? parseInt(event.target.value) : null;
    setTempCategory(categoryId);
    setSelectedCategories(categoryId)
    setSelectedSubcategories(null);
  };
  
  const handleSubcategoriesUpdate = (selectedOption) => {
    const newSubcategories = selectedOption ? [selectedOption] : [];
    setSelectedSubcategories(newSubcategories);
  };

  const handleEditClick = (video) => {
    setTempVideo(video.video);
    setTempCategory(video.category_id);
    setIsEditing(true);
    setTempTitle(video.title);
    setTempDescription(video.description);
    const initialSecondaryCategories = video.tags.map((tag) => ({
      id: tag.id,
      category_id: tag.category_id, 
      category_name: tag.category_name
    }));
    setSelectedSecondaryCategories(initialSecondaryCategories)
    setEditingVideoId(video.id);
  };

  const deleteSecondaryCategoryVideo = async (id) => {
    try {
      const response = await deleteSecondaryCategoryVideoApi(id);
      if (response.status === 200) {
        toast({
          title: "Tag deletada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao deletar a tag",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSaveClick = async () => {
    const formattedSubcategories =
      selectedSubcategories && selectedSubcategories.length > 0
        ? selectedSubcategories.map((option) => ({
            subcategory_id: option.value,
          }))
        : [];
    const updatedVideo = {
      id: editingVideoId,
      title: tempTitle,
      description: tempDescription,
      video: video,
      category_id: selectedCategories,
      secondary_categories: selectedSecondaryCategories,
      subcategories: formattedSubcategories
    };

    try {
      const response = await updateVideoApi(editingVideoId, updatedVideo);
      if (response.status === 200) {
        const updatedVideos = videos.map((video) => {
          if (video.id === editingVideoId) {
            return updatedVideo;
          }
          return video;
        });
        setVideos(updatedVideos);
        toast({
          title: "Vídeo atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listVideo();
      }
    } catch (error) {
      toast({
        title: "Erro ao atualizar vídeo",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log(error);
    }

    setIsEditing(false);
    setEditingVideoId(null);
  };

  const deleteVideo = async (videoId) => {
    const mensagem = "Tem certeza que deseja excluir o vídeo?";
    const confirmacao = window.confirm(mensagem);
    if (confirmacao) {
      try {
        const response = await deleteVideoApi(videoId);
        if (response.status === 200) {
          const updatedVideos = videos.filter((video) => video.id !== videoId);
          setVideos(updatedVideos);
          toast({
            title: "Vídeo excluído com sucesso",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          listVideo();
        }
      } catch (error) {
        toast({
          title: "Erro ao excluir vídeo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.log(error);
      }
    }
  };

  async function listVideo() {
    try {
      const response = await listVideosProlabApi(categoryId);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi() {
    try {
      const response = await learningCategoriesApi();
      const categoriesOrdered = [...response.data?.[0]]?.sort((a, b) => {
        const nameA = a.category?.toLowerCase() || ''; 
        const nameB = b.category?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      console.log(response.data?.[0]);
      setCategories(categoriesOrdered);
      setOptions(
        categoriesOrdered?.map((index) => ({
          value: index.id,
          label: index.category,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };
  const handleVideoChange = (event) => {
    setVideo(event.target.value);
    console.log(event);
  };

  const handleEditClickClose = (video) => {
    setIsEditing(false);
  };
  const formatUrl = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
        const videoId = urlObj.searchParams.get("v"); 
        if (videoId) {
          return `https://www.youtube.com/embed/${videoId}`;
        }
      } 
    } catch (error) {
      console.error("URL inválida:", error);
    }
    return ""; 
  }
  const saveInfo = async () => {
    const urlFormat = formatUrl(video);
    const formattedSubcategories =
      selectedSubcategories && selectedSubcategories.length > 0
        ? selectedSubcategories.map((option) => ({
            subcategory_id: option.value,
          }))
        : [];
    if (!urlFormat) {
      toast({
        title: "URL inválida",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const data = {
        title: title,
        video: urlFormat,
        description: description,
        category_id: selectedCategory,
        secondary_categories: selectedSecondaryCategories,
        subcategories: formattedSubcategories
        
      };
      try {
        const response = await createVideoProlabApi(data);
        console.log(response);
        setTitle("");
        setVideo("");
        setDescription("");
        setSelectedCategory("");
        setSelectedSubcategories([]);
        if (response.status === 200) {
          // Faça algo com a resposta do servidor
          toast({
            title: "Vídeo adicionado com sucesso",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          listVideo();
        }
        setCategories("");
        setTitle("");
        setDescription("");
        setTimeout(() => {
          getCategoriesApi();
        }, 1000); // Ag

        listVideo();
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function listVideoCategory(id) {
    try {
      const response = await listVideosProlabApi(id);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  async function listVideosSubcategory(id, sub) {
    try {
      const response = await listVideosSubcategoryApi(id, sub);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  const handleCategoryClick = async (category) => {
    const newSelectedCategory = category.id === selectedCategory ? null : category.id;
    
    setSelectedCategory(newSelectedCategory);
    setCategoryId(category.id);
    setCategoryName(category.category);
    setSelectedSubcategory(null);
    listVideoCategory(category.id);

    try {
      const response = await listVideosProlabApi(category.id);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubcategoryClick = async (sub) => {
    setSelectedSubcategory(sub.id);
    listVideosSubcategory(categoryId, sub.id);
  };

  const handleChangeSecondaryCategories = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
    }));

    // Atualize o estado 'selectedSecondaryCategories' com os dados formatados
    setSelectedSecondaryCategories(formattedOptions);

    // Exiba os dados formatados no console
    console.log(formattedOptions);
  };

  const handleChangeSecondaryCategoriesUpdate = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
      category_name: option.label, 
    }));

    setSelectedSecondaryCategories(formattedOptions);
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 2000); // Ag

    listVideo();
  }, []);
  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>

              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((category) => {
                  const { text, islimitWords } = limitToTwoWords(
                    category.category
                  );
                  const hasSubcategories =
                    category.subcategories_continuing_learning?.length > 0;

                  return (
                    <Box key={category.id}>
                      {islimitWords ? (
                        <Tooltip
                          label={category.category}
                          fontSize="sm"
                          hasArrow
                          bg="white"
                          color="black"
                        >
                          <Tab
                            mt={"1.5px"}
                            fontSize={"14px"}
                            _hover={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                            _selected={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                            onClick={() => handleCategoryClick(category)}
                          >
                            {text}
                          </Tab>
                        </Tooltip>
                      ) : (
                        <Tab
                        
                          mt={"1.5px"}
                          fontSize={"14px"}
                          _hover={{ backgroundColor: "black", color: "white" }}
                          _selected={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                          onClick={() => handleCategoryClick(category)}
                        >
                          {category.category}
                        </Tab>
                      )}

                      {hasSubcategories && selectedCategory === category.id && (
                        <Box ml={4} mt={2}>
                          {category.subcategories_continuing_learning.map(
                            (sub) => (
                              <Tab
                                key={sub.id}
                                textAlign="left"
                                justifyContent="flex-start"
                                fontSize={"12px"}
                                _hover={{
                                  backgroundColor: "gray.600",
                                  color: "white",
                                }}
                                onClick={() => handleSubcategoryClick(sub)}
                              >
                                {sub.name}
                              </Tab>
                            )
                          )}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </TabList>
            </Box>
          </Tabs>

          <Box
            bg={"#ffff"}
            borderRadius={"15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            w={"57vw"}
            p={5}
            position={"relative"}
            ml={"250px"}
            mt={"100px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <h1 fontSize="md">Adicionar vídeo</h1>
              <Button onClick={saveInfo}>Salvar</Button>
            </Box>
            <Box mt={"20px"} mb={"20px"}>
              <Text>Adicione o link do vídeo</Text>
              <Input
                defaultValue=""
                type="text"
                placeholder={"Digite Aqui"}
                value={video}
                onChange={handleVideoChange}
              />
            </Box>

            <Box>
              <Text>Título</Text>
              <Input
                placeholder={"Digite Aqui"}
                value={title}
                onChange={handleTitleChange}
              />
            </Box>

            <Box mt={"20px"}>
              <Text>Selecione a Categoria:</Text>
              <Select
                value={selectedCategory || ""}
                onChange={(e) => {
                  const categoryId = e.target.value
                    ? parseInt(e.target.value)
                    : null;
                  setSelectedCategory(categoryId);
                  setSelectedSubcategories(null);
                }}
                placeholder="Selecione a categoria"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category}
                  </option>
                ))}
              </Select>
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione as categorias secundárias:</Text>
              <ReactSelect
                id="categoryReactSelect"
                isMulti
                options={options}
                onChange={handleChangeSecondaryCategories}
                styles={customStyles}
              />
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione as Subcategorias:</Text>
              <ReactSelect
                options={
                  selectedCategory
                    ? categories
                        .find((cat) => cat.id === selectedCategory)
                        ?.subcategories_continuing_learning.map((sub) => ({
                          value: sub.id,
                          label: sub.name,
                        })) || []
                    : []
                }
                value={
                  selectedSubcategories?.[0] || selectedSubcategories || null
                }
                onChange={(selectedOption) => {
                  const newSubcategories = selectedOption
                    ? [selectedOption]
                    : [];
                  setSelectedSubcategories(newSubcategories);
                }}
                placeholder={
                  !selectedCategory
                    ? "Selecione uma categoria primeiro"
                    : "Selecione uma Subcategoria"
                }
                isDisabled={!selectedCategory}
                styles={customStyles}
                isClearable
              />
            </Box>
            <Stack margin="15px 0">
              <Text fontSize="md">Descrição</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleDescriptionChange}
                value={description}
              />
            </FormControl>
          </Box>

          <Box marginTop={"20px"}>
            {videos &&
              videos.map((index) => (
                <Box
                  key={index.id}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  width={"57vw"}
                  ml={"250px"}
                  mt={"20px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Vídeo Provatis</Text>
                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    >
                      {isEditing && editingVideoId === index.id ? (
                        <>
                          <AiOutlineSave
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={handleSaveClick}
                          />
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClickClose(index)}
                          />
                        </>
                      ) : (
                        <>
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClick(index)}
                          />
                          <AiOutlineDelete
                            cursor={"pointer"}
                            fontSize={"20px"}
                            color="red"
                            onClick={() => {
                              deleteVideo(index.id);
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}{" "}
                    </Text>
                    <Box ml={2}>
                      {index?.tags?.map((tag) => (
                        <Badge
                          key={tag.id} // Supondo que 'id' seja um identificador único para cada tag
                          colorScheme="green"
                          borderRadius="full"
                          mt={2}
                          mr={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          px={2}
                          py={1}
                        >
                          <span>{tag.category_name}</span>
                          <IconButton
                            icon={<CloseIcon />}
                            size="xs"
                            colorScheme="red"
                            ml={2}
                            onClick={() => deleteSecondaryCategoryVideo(tag.id)}
                          />
                        </Badge>
                      ))}
                    </Box>
                    <Box ml={1} mt={4}>
                    {index?.subs?.map((sub) => (
                      <Box
                        key={sub.id}
                        display="inline-block"
                        bg="gray.200"
                        color="gray.800"
                        borderRadius="full"
                        fontSize="xs"
                        p={1}
                        mr={2}
                        position="relative"
                      >
                        {sub.category_name}
                      </Box>
                    ))}
                  </Box>
                  </Box>
                  <Box mt={"20px"} mb={"15px"}>
                    {isEditing && editingVideoId === index.id ? (
                      <>
                        <Text>Adicione o Titulo</Text>
                        <Input
                          value={tempTitle}
                          onChange={(e) => setTempTitle(e.target.value)}
                        />
                      </>
                    ) : (
                      <Text fontSize={"24px"}>{index.title}</Text>
                    )}
                  </Box>

                  <Box mb={"15px"}>
                    {isEditing && editingVideoId === index.id ? (
                      <>
                        <Text>Selecione a Categoria:</Text>
                        <Select
                          defaultValue={tempCategory} 
                          onChange={handleCategoryUpdate}
                          placeholder="Selecione a categoria"
                        >
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.category}
                            </option>
                          ))}
                        </Select>

                        <Box mt={"20px"}>
                          <Text>Selecione as categorias secundárias:</Text>
                          <ReactSelect
                            id="categoryReactSelect"
                            value={selectedSecondaryCategories.map(tag => ({
                              value: tag.category_id,
                              label: tag.category_name,
                            }))}
                            isMulti
                            options={options}
                            onChange={handleChangeSecondaryCategoriesUpdate}
                            styles={customStyles}
                          />
                        </Box>
                        <Box mt={"20px"}>
                          <Text>Selecione as Subcategorias:</Text>
                          <ReactSelect
                          key={tempCategory} 
                            options={
                              tempCategory
                                ? categories
                                    .find((cat) => cat.id === tempCategory)
                                    ?.subcategories_continuing_learning.map(
                                      (sub) => ({
                                        value: sub.id,
                                        label: sub.name,
                                      })
                                    ) || []
                                : []
                            }
                            value={
                              selectedSubcategories?.[0] ||
                              selectedSubcategories ||
                              null
                            }
                            onChange={handleSubcategoriesUpdate}
                            placeholder={
                              !tempCategory
                                ? "Selecione uma categoria primeiro"
                                : "Selecione uma Subcategoria"
                            }
                            isDisabled={!tempCategory}
                            styles={customStyles}
                            isClearable
                          />
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box>
                    {isEditing && editingVideoId === index.id ? (
                      <JoditEditor
                        value={tempDescription}
                        onChange={(value) => setTempDescription(value)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: index.description,
                        }}
                      />
                    )}
                  </Box>

                  {isEditing && editingVideoId === index.id ? (
                    <>
                      <Text>Adicione aqui o link do Video</Text>
                      <Input
                        value={tempVideo}
                        onChange={(value) => setTempVideo(value)}
                        placeholder="Link"
                      />
                    </>
                  ) : (
                    <AspectRatio ratio={16 / 9}>
                      <iframe src={index.video} allowFullScreen />
                    </AspectRatio>
                  )}

                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
