import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  RadioGroup,
  useToast,
  Textarea,
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
  Tooltip,
} from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import { OrderContext } from "../../contexts/Order";
import { useNavigate } from "react-router-dom";

export default function FeedbackOrder() {
  const orderID = localStorage.getItem("orderID");
  //const order = JSON.parse(localStorage.getItem("order"));
  const toast = useToast();
  const [order, setOrder] = useState();
  const [showText, setShowText] = useState(false);
  const {
    feedbackQuestionsApi,
    sendFeedbackApi,
    getOrderFeedbackApi,
    showExpertOrderFeedbackApi,
  } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [positiveFeedback, setPositiveFeedback] = useState("");
  const [negativeFeedback, setNegativeFeedback] = useState("");
  const [feedbacksStatus, setFeedbacksStatus] = useState([]);
  const [expertFeedback, setExpertFeedback] = useState("");
  const [expertFeedbackText, setExpertFeedbackText] = useState("");
  const [qualityText, setQualityText] = useState();
  const [qualityRevision, setQualityRevision] = useState();
  const [isExpertFeedbackModalOpen, setExpertFeedbackModalOpen] =
    useState(false);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const { getOrderInfo } = useContext(OrderContext);
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };
  const handleMouseEnter = (id) => {
    setShowText((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id) => {
    setShowText((prev) => ({ ...prev, [id]: false }));
  };
  function areAllQuestionsAnswered() {
    const questionIds = Object.keys(questions);
    const answeredQuestionIds = Object.keys(answers);
    return questionIds.every((questionId) =>
      answeredQuestionIds.includes(questionId)
    );
  }

  async function getQuestions(order_change) {
    try {
      const response = await feedbackQuestionsApi();
      let questionsPlan;
      if (order_change === 1) {
        questionsPlan = response.data?.[0].filter(
          (question) => question.feedback_category_id !== 9
        );
      } else {
        questionsPlan = response.data?.[0];
      }
      setQuestions(questionsPlan);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFeedbacksStatus() {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await getOrderFeedbackApi(orderID);
      setFeedbacksStatus(response.data?.[0]);
      console.log("STATUS", response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  async function getOrderDetails() {
    const orderID = localStorage.getItem("orderID");
    try {
      const orderInfoApi = await getOrderInfo(orderID);
      setOrder(orderInfoApi.data?.[0]);
      localStorage.setItem("order", JSON.stringify(orderInfoApi.data?.[0]));
      const order = JSON.parse(localStorage.getItem("order"));
      await fetchData(order);
    } catch (error) {
      console.log(error);
    }
  }
  async function answersFunction() {
    const initialAnswers = {};
    Object.values(questions).forEach((question) => {
      initialAnswers[question.feedback_category_id] = "";
    });
    setAnswers(initialAnswers);
  }
  async function fetchData(orderChange) {
    try {
      await getQuestions(orderChange?.plan_id);
      getFeedbacksStatus();
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getOrderDetails();
  }, [orderID]);

  useEffect(() => {
    getFeedbacksStatus();
    getQuestions();
  }, []);

  const handleAnswerChange = (question, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: value,
    }));
  };
  useEffect(() => {
    answersFunction();
  }, [questions]);

  const handleSubmit = async () => {
    if (
      !Object.values(answers).every((value) => value !== "") ||
      !positiveFeedback ||
      !negativeFeedback
    ) {
      setLoading(false);
      toast({
        title: "Por favor, responda todas as perguntas do feedback.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    onClose();
    const values = Object.entries(answers).map(([questionId, value]) => ({
      question_id: parseInt(questionId),
      value: parseInt(value),
    }));

    const data = {
      order_id: orderID,
      values: values,
      positives: positiveFeedback,
      negatives: negativeFeedback,
      quality: qualityText,
      qualityRevision: qualityRevision,
    };
    setExpertFeedbackModalOpen(false);
    try {
      const inviteExpert = await sendFeedbackApi(data);
      if (inviteExpert.status === 200) {
        toast({
          title: "Feedback Enviado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        clearFields();
        getFeedbacksStatus();
        getQuestions();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const openExpertFeedbackModal = () => {
    setExpertFeedbackModalOpen(true);
  };

  const closeExpertFeedbackModal = () => {
    setExpertFeedbackModalOpen(false);
    clearFields();
  };

  const clearFields = () => {
    setAnswers({});
    setPositiveFeedback("");
    setNegativeFeedback("");
    setQualityText("");
    setQualityRevision("");
  };

  async function showFeedbackUser(userID) {
    const orderID = localStorage.getItem("orderID");
    try {
      const response = await showExpertOrderFeedbackApi(userID, orderID);
      setExpertFeedback(response.data?.["feedbacksQuestions"]);
      console.log("FEEDBACK QUESTONS", response.data?.["feedbacksQuestions"]);
      setExpertFeedbackText(response.data?.["feedbackText"]);
      openExpertFeedbackModal();
      console.log("ATENCAO");
    } catch (error) {
      console.log(error);
    }
  }

  const hideFeedbackButton = () => {
    let result = true;

    if (
      userInfo &&
      userInfo.permission?.expert_role_id !== 2 &&
      userInfo.permission?.expert_role_id !== 3
    ) {
      return false;
    }

    if (feedbacksStatus && userInfo.permission?.expert_role_id === 2) {
      for (let index = 0; index < feedbacksStatus.length; index++) {
        const feedback = feedbacksStatus[index];

        if (
          feedback.translator_feedback === 1 &&
          userInfo &&
          userInfo.permission?.expert_role_id === 2
        ) {
          result = false;
          break;
        }
      }
    }

    if (feedbacksStatus && userInfo.permission?.expert_role_id === 3) {
      if (
        (feedbacksStatus.translator_feedback === 1 &&
          userInfo &&
          userInfo.permission?.expert_role_id === 2) ||
        (feedbacksStatus.revisor_feedback === 1 &&
          userInfo &&
          userInfo.permission?.expert_role_id === 3)
      ) {
        result = false;
      }
    }

    return result;
  };

  return (
    <>
      <div>
        {loading && <div style={overlayStyle}>Enviando Feedback...</div>}
      </div>

      <Box
        bg={"#ffff"}
        borderRadius={"15px "}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        p={5}
        ml={{
          base: 0,
          md: "180px",
        }}
        maxWidth={"100%"}
      >
        <Flex pb={5}>
          <Box>Feedbacks </Box>
        </Flex>
        <Text>
          {" "}
          {order?.expert_test_translation === 1 && userInfo?.expertInfo?.expert_test !== 1 
            ? "A Tradução deste serviço foi realizada por um expert em avaliação"
            : ""}
        </Text>
        {hideFeedbackButton() ? (
          <>
            <Box mt={5}>
              <Button onClick={onOpen}>Realizar Feedback</Button>
            </Box>
          </>
        ) : null}
      </Box>

      {feedbacksStatus !== null ? (
        <>
          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={{
              base: 0,
              md: "180px",
            }}
            maxWidth={"100%"}
          >
            <Table>
              <Thead>
                <Tr>
                  <Td>Expert</Td>
                  <Td>Status feedback</Td>
                  <Td>Feedback</Td>{" "}
                </Tr>
              </Thead>
              <Tbody>
                {feedbacksStatus.length > 0 ? (
                  feedbacksStatus.map((index) => (
                    <Tr key={index.id}>
                      <Td>
                        {index.translator === 1
                          ? "Tradutor"
                          : index.revisor === 1
                          ? "Revisor"
                          : ""}
                      </Td>
                      <Td>
                        {index.translator === 1
                          ? index.translator_feedback === 0
                            ? "Pendente"
                            : "Recebido"
                          : index.revisor === 1
                          ? index.revisor_feedback === 0
                            ? "Pendente"
                            : "Recebido"
                          : ""}
                      </Td>
                      {index.translator_feedback === 1 ||
                      index.revisor_feedback === 1 ? (
                        <Td>
                          <Button
                            onClick={() =>
                              showFeedbackUser(index.expert_id_receive_feedback)
                            }
                          >
                            Ver Feedback
                          </Button>
                        </Td>
                      ) : (
                        " "
                      )}
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    {" "}
                    <Td>
                      {feedbacksStatus.translator === 1
                        ? "Tradutor"
                        : feedbacksStatus.revisor === 1
                        ? "Revisor"
                        : ""}
                    </Td>
                    <Td>
                      {feedbacksStatus.translator === 1
                        ? feedbacksStatus.translator_feedback === 0
                          ? "Pendente"
                          : "Recebido"
                        : feedbacksStatus.revisor === 1
                        ? feedbacksStatus.revisor_feedback === 0
                          ? "Pendente"
                          : "Recebido"
                        : ""}
                    </Td>
                    {feedbacksStatus.translator_feedback === 1 ||
                    feedbacksStatus.revisor_feedback === 1 ? (
                      <Td>
                        <Button
                          onClick={() =>
                            showFeedbackUser(
                              feedbacksStatus.expert_id_receive_feedback
                            )
                          }
                        >
                          Ver Feedback
                        </Button>
                      </Td>
                    ) : (
                      ""
                    )}
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </>
      ) : (
        ""
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={"900px"} maxWidth={"100%"}>
          <ModalHeader>Feedback de Desempenho</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.values(questions).map((question) => (
              <FormControl key={question.feedback_category_id} mb={4}>
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: question.question }}
                    />
                    <Tooltip
                      label={question.help}
                      isOpen={showText[question.feedback_category_id]}
                      placement="bottom"
                      backgroundColor="#f0f0f0"
                      color="black"
                      fontSize="14px"
                      lineHeight="18px"
                      padding="10px"
                      width="400px"
                    >
                      <Text as="span" fontSize="sm" ml={2} mb={0}>
                        <FcInfo
                          size={18}
                          onMouseEnter={() =>
                            handleMouseEnter(question.feedback_category_id)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(question.feedback_category_id)
                          }
                        />
                      </Text>
                    </Tooltip>
                  </div>

                  <RadioGroup
                    mt={5}
                    onChange={(value) =>
                      handleAnswerChange(question.feedback_category_id, value)
                    }
                    value={answers[question.feedback_category_id]}
                  >
                    <Stack direction="row">
                      {question.feedback_category_id === 9 ||
                      question.feedback_category_id === 10 ? (
                        <>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                          <Radio value="6">6</Radio>
                          <Radio value="7">7</Radio>
                          <Radio value="8">8</Radio>
                          <Radio value="9">9</Radio>
                          <Radio value="10">10</Radio>
                        </>
                      ) : (
                        <>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                        </>
                      )}
                    </Stack>
                  </RadioGroup>
                </>
              </FormControl>
            ))}
            <FormControl>
              <FormLabel>Pontos positivos na revisão deste Expert?</FormLabel>
              <Textarea
                onChange={(e) => setPositiveFeedback(e.target.value)}
              ></Textarea>
            </FormControl>
            <FormControl>
              <FormLabel>
                Pontos que podem melhorar na revisão deste Expert?
              </FormLabel>
              <Textarea
                onChange={(e) => setNegativeFeedback(e.target.value)}
              ></Textarea>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Enviar Respostas
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {expertFeedback && (
        <Modal
          isOpen={isExpertFeedbackModalOpen}
          onClose={closeExpertFeedbackModal}
        >
          <ModalOverlay />
          <ModalContent width={"900px"} maxWidth={"100%"}>
            <ModalHeader>FeedBack Expert</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {expertFeedback.map(
                (feedbackItem) =>
                  feedbackItem.value && (
                    <FormControl key={feedbackItem.id} mb={4}>
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div
                            mb={4}
                            fontSize={"14px"}
                            fontWeight={"800"}
                            dangerouslySetInnerHTML={{
                              __html: feedbackItem.question,
                            }}
                          />
                          <Tooltip
                            label={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: feedbackItem.help,
                                }}
                              />
                            }
                            placement="bottom"
                            backgroundColor="#f0f0f0"
                            color="black"
                            fontSize="14px"
                            lineHeight="18px"
                            padding="10px"
                          >
                            <Text
                              as="span"
                              fontSize="sm"
                              color="gray.500"
                              ml={2}
                              mb={0}
                            >
                              <FcInfo
                                size={18}
                                onMouseEnter={() =>
                                  handleMouseEnter(feedbackItem.help)
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(feedbackItem.help)
                                }
                              />
                            </Text>
                          </Tooltip>
                        </div>

                        <RadioGroup
                          mt={5}
                          value={feedbackItem.value.toString()}
                          isDisabled
                        >
                          <Stack direction="row">
                            {feedbackItem.feedback_category_id === 9 ||
                            feedbackItem.feedback_category_id === 10
                              ? Array.from({ length: 10 }, (_, i) => (
                                  <Radio
                                    key={i + 1}
                                    value={(i + 1).toString()}
                                    isDisabled
                                  >
                                    {i + 1}
                                  </Radio>
                                ))
                              : Array.from({ length: 5 }, (_, i) => (
                                  <Radio
                                    key={i + 1}
                                    value={(i + 1).toString()}
                                    isDisabled
                                  >
                                    {i + 1}
                                  </Radio>
                                ))}
                          </Stack>
                        </RadioGroup>
                      </>
                    </FormControl>
                  )
              )}
              {expertFeedbackText && (
                <>
                  {expertFeedbackText.positives && (
                    <Box>
                      <Text>Pontos positivos na revisão deste Expert?</Text>
                      <Text fontWeight="bold" color="blue">
                        {expertFeedbackText.positives}
                      </Text>
                    </Box>
                  )}
                  {expertFeedbackText.negatives && (
                    <Box>
                      <Text>
                        Pontos que podem melhorar na revisão deste Expert?
                      </Text>
                      <Text fontWeight="bold" color="blue">
                        {expertFeedbackText.negatives}
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
