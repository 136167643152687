import React, { useContext, useEffect, useState, useRef } from "react";
import api from "../../services/api";
import Navbar from "../../components/Navbar/Navbar";
import {
  ChakraProvider,
  useToast,
  Box,
  Flex,
  Button,
  FormControl,
  Input,
  Stack,
  HStack,
  Text,
  Spacer,
  Container,
  Icon,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  Switch,
  FormErrorMessage
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";
import { PlanContext } from "../../contexts/Plan";
import JoditEditor from "../../components/Editor";
import { OrderContext } from "../../contexts/Order";
import { IoAddCircleOutline, IoTrashOutline } from "react-icons/io5";
import { AiOutlineUpload } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import Select, { components } from "react-select";
export default function CreateOrder() {
  const toast = useToast();
  const { listClientsApi } = useContext(UserContext);
  const { createOrderApi } = useContext(OrderContext);

  const [showModal, setShowModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [clients, setClients] = useState([]);
  const [inputs, setInputs] = useState([]);
  const fileInputRefs = useRef([]);
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [planID, setPlanID] = useState("");
  const [fastDelivery, setFastDelivery] = useState(0);
  const [initialLanguage, setInitialLanguage] = useState("");
  const [finalLanguage, setFinalLanguage] = useState("");
  const [fastDeliveryDays, setFastDeliveryDays] = useState("");
  const [magazineName, setMagazineName] = useState("");
  const [magazineEdit, setMagazineEdit] = useState("");
  const [wordsNumberOriginal, setWordsNumberOriginal] = useState("");
  const [wordsNumberActual, setWordsNumberActual] = useState("");
  const [totalReference, setTotalReference] = useState("");
  const [figures, setFigures] = useState("");
  const [areaID, setAreaID] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [description, setDescription] = useState("");
  const [translated, setTranslated] = useState(false);
  const [services, setServices] = useState([]);
  const [aditionalServices, setAditionalServices] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [fastDeliveryDaysMagazine, setFastDeliveryDaysMagazine] = useState("");
  const [manualSelect, setManualSelect] = useState(true);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const { type } = useParams();
  const [errors, setErrors] = useState({});
  
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleInputTitle = (e) => setTitle(e.target.value);
  const handleInputClientID = (e) => setClient(e.target.value);

  
  const handleInputTypeDocument = (selectedOption) => setTypeDocument(selectedOption.value);
  const handleInputPlanID = (selectedOption) => {
    setPlanID(selectedOption.value);
    if (selectedOption.value == 3) {
      setMagazineEdit(1);
    } else {
      setMagazineEdit(0);
    }
  };
  const handleInputInitialLanguage = (selectedOption) => setInitialLanguage(selectedOption.value);
  const handleInputFinalLanguage = (selectedOption) => setFinalLanguage(selectedOption.value);
  const handleInputFastDelivery = (e) => setFastDelivery(e.target.checked);
  const handleInputFastDeliveryDays = (e) =>
    setFastDeliveryDays(e.target.value);
  const handleInputFastDeliveryDaysMagazine = (e) =>
    setFastDeliveryDaysMagazine(e.target.value);
  const handleInputMagazineName = (e) => setMagazineName(e.target.value);
  const handleInputMagazineEdit = (e) => {
    setMagazineEdit(e.target.checked ? 1 : 0);
  };
  
  const handleInputWordsOriginal = (e) =>
    setWordsNumberOriginal(e.target.value);
  const handleInputWordsActual = (e) => setWordsNumberActual(e.target.value);
  const handleInputFigures = (e) => setFigures(e.target.value);
  const handleInputTotalReference = (e) => setTotalReference(e.target.value);
  const handleInputAreaID = (e) => setAreaID(e.target.value);
  const handleTranslated = (e) => setTranslated(e.target.checked);
  const handleManualSelect = (e) => {
    setManualSelect(e.target.checked);
  };
  const handleInputKeywords = (e) => {
    const array = e.target.value.replace(";", "").split(" ");
    setKeywords(array);
  };

  const handleInputDescription = (e) => {
    setDescription(e);
  };

  const handleBlurAreaChange = (content) => {
    setTextAreaValue(content);
  };

  const adicionarInput = () => {
    setInputs([...inputs, { type: "" }]);
  };

  const handleChange = (index) => (event) => {
    const newInputs = [...inputs];
    newInputs[index].file = event.target.files[0];
    setInputs(newInputs);
  };

  const excluirInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  async function listClients() {
    try {
      const clients = await listClientsApi();
      const clientsOrdered = [...clients.data?.[0]]?.sort((a, b) => {
        const nameA = a.user?.name?.toLowerCase() || ''; 
        const nameB = b.user?.name?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      setClients(clientsOrdered);
    } catch (error) {
      console.log(error);
    }
  }
  const clientOptions = clients.map((client) => ({
    value: client.user.id,
    label: client.user.name
  }));
  
  async function createOrder() {
    let newErrors = {};
    if (!planID) newErrors.planID = true;
    if (!client) newErrors.client = true;
    if (!wordsNumberOriginal) newErrors.wordsNumberOriginal = true;
    if (!title) newErrors.title = true;
    if (!initialLanguage) newErrors.initialLanguage = true;
    if (!finalLanguage) newErrors.finalLanguage = true;
    if (keywords.length === 0) newErrors.keywords = true;
    if (!files || files.length === 0) newErrors.files = true;
    if (!textAreaValue.trim()) newErrors.abstract = true;
    if (magazineEdit == 1 && !totalReference) newErrors.totalReference = true;
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast({
        title: "Por favor, preencha todos os campos.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const requestData = {
        title: title,
        client_id: client,
        abstract: textAreaValue,
        type_document: typeDocument,
        plan_id: type === "geral" ? planID : 4,
        fast_delivery: fastDelivery,
        language_origin: initialLanguage,
        language_final: finalLanguage,
        fast_delivery: fastDelivery,
        fast_delivery_days: fastDeliveryDays,
        fast_delivery_magazine: fastDelivery == 1 ? 1 : 0,
        fast_delivery_days_magazine:
          type === "geral" ? fastDeliveryDaysMagazine : fastDeliveryDays,
        magazine_name: magazineName,
        magazine_edit: type === "geral" ? magazineEdit : 1,
        words_number_original: wordsNumberOriginal,
        total_reference: totalReference,
        qty_figure: figures,
        services: services,
        aditional_services: aditionalServices,
        area_id: areaID,
        keywords: keywords,
        discount: discount,
        description: description,
        translated: type === "geral" ? translated : 0,
        manual_select: manualSelect,
        type_service: type === "geral" ? "general" : "edit-norms",
      };
      
      setShowModal(true);
      const responseData = await createOrderApi(requestData);

      // Extrair o ID da order da resposta da API
      const orderId = responseData.data?.[0].id;
      const userId = responseData.data?.[0].user_id;

      setFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      formData.append("order_id", orderId);
      formData.append("user_id", userId);
      const token = localStorage.getItem("token");

      try {
        await api.post(
          `orders/${orderId}/documents/client/upload-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setFiles([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Erro no upload dos arquivos:", error);
      }

      if (responseData.status === 200) {
        // Faça algo com a resposta do servidor
        toast({
          title: "Serviço Criado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      if (responseData.status === 202) {
        // Faça algo com a resposta do servidor
        toast({
          title:
            "Serviço Criado, mas precisará selecionar o expert manualmente",
          status: "warning",
          duration: 7000,
          isClosable: true,
        });
      }
      setShowModal(false);
      console.log(responseData);
      // Redirecione para a página de serviços
      window.location.pathname = "/servicos";
    } catch (error) {
      setShowModal(false);
      toast({
        title:
          "Não foi possível criar o serviço, verifique os campos e tente novamente",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    listClients();
  }, []);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, inputs.length);
  }, [inputs]);

  return (
    <>
      <Navbar />

      <Container position="absolute" top={120} ml={300} minWidth={"75vw"}>
        <Box
          bg="#ffff"
          borderRadius="15px"
          boxShadow="0px 7px 16px 4px #EAEAEA"
        >
          <Flex display="flex" padding={5} alignItems="center">
            <Box>
              <Text fontSize="24px" fontWeight="600">
                Adicionar um novo Serviço{" "}
                {type == "geral" ? "Geral" : "de Edição de Normas"}
              </Text>
            </Box>

            <Spacer />
          </Flex>
        </Box>

        <Flex
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="45px"
        >
          <Box
            marginBottom={5}
            boxShadow="xl"
            padding={35}
            borderRadius={15}
            backgroundColor="white"
          >
            <Box>
              <Stack marginTop="15px" marginBottom="15px">
                <Text fontSize="lg">Detalhes</Text>
              </Stack>
              <FormControl display="inline-flex">
                {clients && (
                  <Box sx={{ width: "25%" }}>
                    <FormControl isInvalid={errors.client}>
                      <Select
                        options={clientOptions}
                        onChange={(selectedOption) => {
                          setClient(selectedOption.value);
                        }}
                        placeholder="Selecione um cliente"
                      />
                      <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                    </FormControl>
                  </Box>
                )}
                {type === "geral" && (
                  <>
                    <Box sx={{ width: "25%" }} ml={2}>
                      <FormControl isInvalid={errors.planID}>
                        <Select
                          onChange={handleInputPlanID}
                          placeholder="Selecione um plano"
                          marginLeft="1%"
                          options={[
                            { value: 1, label: "Basic" },
                            { value: 2, label: "Pro" },
                            { value: 3, label: "Full" },
                          ]}
                        ></Select>
                        <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Box sx={{ width: "30%" }} ml={2}>
                      <Select
                        onChange={handleInputTypeDocument}
                        placeholder="Selecione tipo de serviço"
                        marginLeft="1%"
                        options={[
                          { value: "Científico", label: "Científico" },
                          { value: "Manual", label: "Manual" },
                        ]}
                      ></Select>
                    </Box>
                  </>
                )}

                <Box sx={{ width: "25%" }} ml={2} marginLeft={"1%"}>
                  <HStack spacing={3}>
                    <Text fontSize="md">Seleção Manual?</Text>
                    <Switch
                      placeholder="Seleção Manual ?"
                      colorScheme="green"
                      onChange={handleManualSelect}
                    ></Switch>
                  </HStack>
                </Box>

                {type === "edicao-normas" && (
                  <>
                    <Input
                      display="inline-block"
                      marginLeft={type === "edicao-normas" ? "1%" : "0"}
                      placeholder="Nome da Revista"
                      onChange={handleInputMagazineName}
                    ></Input>
                    <Input
                      onChange={handleInputTotalReference}
                      marginLeft="1%"
                      placeholder="Numero de referências"
                    />
                  </>
                )}
              </FormControl>

              <FormControl marginTop="1%" display="inline-flex">
                <Box sx={{ width: "25%" }} ml={2} marginRight={"1%"}>
                  <HStack spacing={3}>
                    <Text fontSize="md">FastDelivery</Text>
                    <Switch
                      placeholder="FastDelivery"
                      colorScheme="green"
                      onChange={handleInputFastDelivery}
                    ></Switch>
                  </HStack>
                </Box>
                {fastDelivery == 1 ? (
                  <Input
                    onChange={handleInputFastDeliveryDays}
                    marginLeft="1%"
                    placeholder="Receber em quantos dias"
                  />
                ) : null}
                <Input
                  onChange={handleInputFigures}
                  marginLeft="1%"
                  placeholder="Quantidade de figuras"
                />

                {type === "geral" && (
                  <FormControl
                    isInvalid={errors.wordsNumberOriginal}
                    isRequired
                  >
                    <Input
                      onChange={handleInputWordsOriginal}
                      marginLeft="1%"
                      placeholder="Quantidade Palavras"
                    />
                    <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                  </FormControl>
                )}
              </FormControl>

              {type === "geral" && (
                <>
                  <Stack marginTop="50px">
                    <Text fontSize="lg">Serviço Adicional</Text>
                  </Stack>
                  <FormControl marginTop="2%">
                    <Box sx={{ width: "30%" }}>
                      <HStack spacing={3}>
                        <Text fontSize="md">Edição das normas da Revista</Text>
                        <Switch
                          isChecked={magazineEdit === 1}
                          placeholder="Seleção Manual ?"
                          colorScheme="green"
                          onChange={handleInputMagazineEdit}
                        ></Switch>
                      </HStack>
                    </Box>
                    {magazineEdit == 1 ? (
                      <>
                        <FormControl marginTop="1%" display="inline-flex">
                          <Input
                            display="inline-block"
                            placeholder="Nome da Revista"
                            onChange={handleInputMagazineName}
                          ></Input>
                          <FormControl
                            isInvalid={errors.totalReference}
                            isRequired
                          >
                            <Input
                              onChange={handleInputTotalReference}
                              marginLeft="1%"
                              placeholder="Numero de referências"
                            />
                            <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                          </FormControl>
                          {fastDelivery == 1 ? (
                            <Input
                              onChange={handleInputFastDeliveryDaysMagazine}
                              marginLeft="1%"
                              placeholder="Receber em quantos dias a edição das normas da revista"
                            />
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </>
              )}

              <Stack marginTop="50px">
                <Text fontSize="lg">Serviço</Text>
              </Stack>
              <FormControl display="inline-flex">
                {type === "geral" && (
                  <Box sx={{ width: "25%" }} ml={2}>
                    <HStack spacing={3}>
                      <Text fontSize="md">O serviço está traduzido?</Text>
                      <Switch
                        placeholder="O serviço está traduzido?"
                        colorScheme="green"
                        onChange={handleTranslated}
                      ></Switch>
                    </HStack>
                  </Box>
                )}
              </FormControl>
              <FormControl display="flex" flexWrap="wrap" gap="10px" mt="15px">
                <FormControl isInvalid={errors.title} flex="1">
                  <Input onChange={handleInputTitle} placeholder="Título" />
                  <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                </FormControl>

                {type === "geral" && (
                  <>
                    <Box flex="1" minWidth="200px">
                      <FormControl isInvalid={errors.initialLanguage}>
                        <Select
                          onChange={handleInputInitialLanguage}
                          placeholder="Idioma de Origem"
                          options={[
                            { value: "PT-BR", label: "Português(BR)" },
                            { value: "EN", label: "Inglês" },
                          ]}
                        />
                        <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Box flex="1" minWidth="200px">
                      <FormControl isInvalid={errors.finalLanguage}>
                        <Select
                          onChange={handleInputFinalLanguage}
                          placeholder="Idioma Final"
                          options={[
                            { value: "PT-BR", label: "Português(BR)" },
                            { value: "EN", label: "Inglês" },
                          ]}
                        />
                        <FormErrorMessage>Campo obrigatório</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </>
                )}
              </FormControl>

              <Stack margin="15px 0">
                <Text fontSize="md">Abstract</Text>
              </Stack>
              <FormControl isInvalid={errors.abstract}>
                <JoditEditor
                  onBlur={handleBlurAreaChange}
                  value={textAreaValue}
                />
                <FormErrorMessage>Campo obrigatório</FormErrorMessage>
              </FormControl>

              <FormControl
                marginTop="2%"
                display="flex"
                flexDirection="column"
                isInvalid={errors.keywords}
              >
                <Input
                  onChange={handleInputKeywords}
                  placeholder="Digite as palavras-chave"
                />
                <FormErrorMessage>Campo obrigatório</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <Stack marginTop="50px" marginBottom="15px">
                <Text fontSize="lg">Arquivo</Text>
              </Stack>
              <Box marginTop="25px">
                <FormControl isInvalid={errors.files}>
                  <Input
                    pt={2}
                    fontSize="12px"
                    type="file"
                    accept=".pdf, .doc, .docx"
                    multiple
                    onChange={handleFileChange}
                  />
                  <FormErrorMessage>
                    É necessário enviar um arquivo.
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>

            <Stack marginTop="50px">
              <Text fontSize="lg">Expert</Text>
            </Stack>

            <Stack margin="15px 0">
              <Text fontSize="md">Descrição (para os experts)</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleInputDescription}
                value={description}
              />
            </FormControl>

            <Box marginTop="2%">
              <Button margin="2%">
                <Link to="/servicos">Voltar</Link>
              </Button>

              <Button colorScheme="blue" onClick={createOrder}>
                Salvar
              </Button>
            </Box>
          </Box>
        </Flex>
      </Container>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign="center">
            <Spinner size="xl" color="blue.500" />
            <Text mt="4">Estamos convidando os experts. Aguarde...</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
