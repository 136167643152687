import React, { useState, useContext, useEffect } from "react";
import JoditEditor from "../../components/Editor";
import {
  useToast,
  Box,
  Button,
  Text,
  Input,
  Stack,
  FormControl,
  Tabs,
  Tab,
  TabList,
  Select,
  Badge,
  Tooltip,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";
import ReactSelect from "react-select";
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineSave,
} from "react-icons/ai";
import { IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export default function TeachingPills() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const {
    listPillsProlabApi,
    createPillProlabApi,
    updatePillApi,
    deletePillApi,
    deleteSecondaryCategoryPillApi,
    listPillsSubcategoryApi
  } = useContext(ProlabContext);
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [editingPillId, setEditingPillId] = useState(null);
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategoryUpdate, setSelectedCategoryUpdate] = useState();
  const [categoryId, setCategoryId] = useState(4);
  const { learningCategoriesApi } = useContext(OrderContext);
  const [pills, setPills] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [tempCategory, setTempCategory] = useState("");
  const [tempCategories, setTempCategories] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState(editingPillId);
  const [options, setOptions] = useState([]);
  const [selectedSecondaryCategories, setSelectedSecondaryCategories] =
    useState([]);
  const [tags, setTags] = useState();

  function limitToTwoWords(text) {
    if (text.length > 16) {
      const islimitWords = true;
      return {text: text.slice(0, 16) + " ...", islimitWords}
    }
    return text;
  }

  const handleEditClick = (pill) => {
    setIsEditing(true);
    setTempTitle(pill.learning_pills);
    setTempDescription(pill.description);
    setEditingPillId(pill.id);
    setTempCategory(pill.category_id);
    const initialSecondaryCategories = pill.tags.map((tag) => ({
      id: tag.id,
      category_id: tag.category_id, 
      category_name: tag.category_name
    }));
    setTempCategories(initialSecondaryCategories);
  };

  const handleEditClickClose = (pill) => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    const formattedSubcategories =
    selectedSubcategories && selectedSubcategories.length > 0
      ? selectedSubcategories.map((option) => ({
          subcategory_id: option.value,
        }))
      : [];
    const updatedPill = {
      id: editingPillId,
      pill: tempTitle,
      description: tempDescription,
      category_id: selectedCategories,
      secondary_categories: selectedSecondaryCategories,
      subcategories: formattedSubcategories
    };

    try {
      const response = await updatePillApi(editingPillId, updatedPill);
      if (response.status === 200) {
        const updatedPills = pills.map((pill) => {
          if (pill.id === editingPillId) {
            return updatedPill;
          }
          return pill;
        });
        setPills(updatedPills);
        toast({
          title: "Pílula atualizada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao atualizar a pílula",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsEditing(false);
    setEditingPillId(null);
  };

  const deletePill = async (pillId) => {
    const mensagem = "Tem certeza que deseja excluir a pílula?";
    const confirmacao = window.confirm(mensagem);
    if (confirmacao) {
      try {
        const response = await deletePillApi(pillId);
        if (response.status === 200) {
          const updatedPills = pills.filter((pill) => pill.id !== pillId);
          setPills(updatedPills);
          toast({
            title: "Pílula deletada com sucesso",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          listPills();
        }
      } catch (error) {
        console.log(error);
        toast({
          title: "Erro ao deletar a pílula",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const deleteSecondaryCategoryPill = async (id) => {
    try {
      const response = await deleteSecondaryCategoryPillApi(id);
      if (response.status === 200) {
        toast({
          title: "Tag deletada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      listPills();
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro ao deletar a tag",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  async function listPills() {
    try {
      const response = await listPillsProlabApi(categoryId);
      console.log(response);
      setPills(response.data?.[0]);
      setSelectedCategory(0);
      setSelectedSecondaryCategories([]);
      setSelectedSubcategories([]);
      setSelectedCategories([]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi() {
    try {
      const response = await learningCategoriesApi();
      const categories = response.data?.[0].filter(item => item.feedback_category_id !== null);
      const categoriesOrdered = [...categories]?.sort((a, b) => {
        const nameA = a.category?.toLowerCase() || "";
        const nameB = b.category?.toLowerCase() || "";
        return nameA.localeCompare(nameB);
      });
      console.log(response.data?.[0]);
      setCategories(categoriesOrdered);
      setOptions(
        categoriesOrdered?.map((index) => ({
          value: index.id,
          label: index.category,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleCategoryChange = (event) => {
    const categoryId = event.target.value ? parseInt(event.target.value) : null;
    setSelectedCategory(categoryId);
    setSelectedSubcategories(null);
  };
  const handleCategoryUpdate = (event) => {
    const categoryId = event.target.value ? parseInt(event.target.value) : null;
    setTempCategory(categoryId);
    setSelectedCategories(categoryId)
    setSelectedSubcategories(null);
  };

  const handleSubcategoriesChange = (selectedOption) => {
    const newSubcategories = selectedOption ? [selectedOption] : [];
    setSelectedSubcategories(newSubcategories);
  };
  const handleSubcategoriesUpdate = (selectedOption) => {
    const newSubcategories = selectedOption ? [selectedOption] : [];
    setSelectedSubcategories(newSubcategories);
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };

  const saveInfo = async () => {
    const formattedSecondaryCategories = selectedSecondaryCategories.map(
      (option) => ({
        category_id: option.value,
      })
    );
    const formattedSubcategories =
      selectedSubcategories && selectedSubcategories.length > 0
        ? selectedSubcategories.map((option) => ({
            subcategory_id: option.value,
          }))
        : [];
    const data = {
      pill: title,
      description: description,
      category_id: selectedCategory,
      secondary_categories: formattedSecondaryCategories,
      subcategories: formattedSubcategories
    };

    try {
      const response = await createPillProlabApi(data);
      console.log(response);
      setTitle("");
      setDescription("");
      setSelectedSecondaryCategories([]);
      setSelectedSubcategories([]);

      if (response.status === 200) {
        toast({
          title: "Pílula criada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        listPills();
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function listPillsCategory(id) {
    try {
      const response = await listPillsProlabApi(id);
      console.log(response);
      setPills(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  async function listPillsSubcategory(id, sub) {
    try {
      const response = await listPillsSubcategoryApi(id, sub);
      console.log(response);
      setPills(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCategoryClick = (category) => {
    const newSelectedCategory = category.id === selectedCategory ? null : category.id;
    
    setSelectedCategory(newSelectedCategory);
    setCategoryId(category.id);
    setCategoryName(category.category);
    listPillsCategory(category.id);
    setSelectedSubcategory(null);
  };
  const handleSubcategoryClick = (sub) => {
    setSelectedSubcategory(sub.id);
    listPillsSubcategory(categoryId, sub.id);
  };

  const handleChangeSecondaryCategories = (selectedOptions) => {
    setSelectedSecondaryCategories(selectedOptions);
  };

  const handleChangeSecondaryCategoriesUpdate = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      category_id: option.value,
    }));

    setSelectedSecondaryCategories(formattedOptions);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Ajuste o valor conforme necessário
    }),
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listPills();
  }, []);

  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>
              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((category) => {
                  const { text, islimitWords } = limitToTwoWords(
                    category.category
                  );
                  const hasSubcategories =
                    category.subcategories_continuing_learning?.length > 0;

                  return (
                    <Box key={category.id}>
                      {islimitWords ? (
                        <Tooltip
                          label={category.category}
                          fontSize="sm"
                          hasArrow
                          bg="white"
                          color="black"
                        >
                          <Tab
                            mt={"1.5px"}
                            fontSize={"14px"}
                            _hover={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                            _selected={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                            onClick={() => handleCategoryClick(category)}
                          >
                            {text}
                          </Tab>
                        </Tooltip>
                      ) : (
                        <Tab
                          mt={"1.5px"}
                          fontSize={"14px"}
                          _hover={{ backgroundColor: "black", color: "white" }}
                          _selected={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                          onClick={() => handleCategoryClick(category)}
                        >
                          {category.category}
                        </Tab>
                      )}

                      {hasSubcategories && selectedCategory === category.id && (
                        <Box ml={4} mt={2}>
                          {category.subcategories_continuing_learning.map(
                            (sub) => (
                              <Tab
                                key={sub.id}
                                textAlign="left"
                                justifyContent="flex-start"
                                fontSize={"12px"}
                                _hover={{
                                  backgroundColor: "gray.600",
                                  color: "white",
                                }}
                                onClick={() => handleSubcategoryClick(sub)}
                              >
                                {sub.name}
                              </Tab>
                            )
                          )}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </TabList>
            </Box>
          </Tabs>

          <Box
            bg={"#ffff"}
            borderRadius={"15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            w={"57vw"}
            p={5}
            position={"relative"}
            ml={"250px"}
            mt={"100px"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <h1 fontSize="md">Adicionar Pílula</h1>
              <Button onClick={() => saveInfo()}>Salvar</Button>
            </Box>
            <Box>
              <Text>Título</Text>
              <Input
                placeholder={"Digite Aqui"}
                value={title}
                onChange={handleTitleChange}
              />
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione a Categoria:</Text>
              <Select
                value={selectedCategory || ""}
                onChange={handleCategoryChange}
                placeholder="Selecione a categoria"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category}
                  </option>
                ))}
              </Select>
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione as categorias secundárias:</Text>
              <ReactSelect
                id="categoryReactSelect"
                isMulti
                options={options}
                value={selectedSecondaryCategories}
                onChange={handleChangeSecondaryCategories}
                styles={customStyles}
              ></ReactSelect>
            </Box>
            <Box mt={"20px"}>
              <Text>Selecione as Subcategorias:</Text>
              <ReactSelect
                options={
                  selectedCategory
                    ? categories
                        .find((cat) => cat.id === selectedCategory)
                        ?.subcategories_continuing_learning.map((sub) => ({
                          value: sub.id,
                          label: sub.name,
                        })) || []
                    : []
                }
                value={
                  selectedSubcategories?.[0] || selectedSubcategories || null
                }
                onChange={handleSubcategoriesChange}
                placeholder={
                  !selectedCategory
                    ? "Selecione uma categoria primeiro"
                    : "Selecione uma Subcategoria"
                }
                isDisabled={!selectedCategory}
                styles={customStyles}
                isClearable
              />
            </Box>
            <Stack margin="15px ">
              <Text fontSize="md">Descrição</Text>
            </Stack>
            <FormControl>
              <JoditEditor
                onBlur={handleDescriptionChange}
                value={description}
              />
            </FormControl>
          </Box>

          <Box marginTop={"20px"}>
            {pills &&
              pills.map((index) => (
                <Box
                  key={index.id}
                  marginTop={"80px"}
                  margin={"20px"}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  width={"57vw"}
                  ml={"250px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Pílula Expert</Text>

                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    >
                      {isEditing && editingPillId === index.id ? (
                        <>
                          <AiOutlineSave
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={handleSaveClick}
                          />
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClickClose(index)}
                          />
                        </>
                      ) : (
                        <>
                          <AiOutlineEdit
                            cursor={"pointer"}
                            fontSize={"20px"}
                            onClick={() => handleEditClick(index)}
                          />
                          <AiOutlineDelete
                            cursor={"pointer"}
                            fontSize={"20px"}
                            color="red"
                            onClick={() => {
                              deletePill(index.id);
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}{" "}
                    </Text>
                    <Box ml={2}>
                      {index?.tags?.map((tag) => (
                        <Box
                          key={tag.id}
                          display="inline-block"
                          bg="green.200"
                          color="green.800"
                          borderRadius="full"
                          p={2}
                          mr={2}
                          position="relative"
                        >
                          {tag.category_name}
                          <IconButton
                            icon={<CloseIcon />}
                            size="sm"
                            color="red.500"
                            position="absolute"
                            top="0"
                            right="0"
                            onClick={() => deleteSecondaryCategoryPill(tag.id)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box ml={1} mt={4}>
                    {index?.subs?.map((sub) => (
                      <Box
                        key={sub.id}
                        display="inline-block"
                        bg="gray.200"
                        color="gray.800"
                        borderRadius="full"
                        fontSize="xs"
                        p={1}
                        mr={2}
                        position="relative"
                      >
                        {sub.category_name}
                      </Box>
                    ))}
                  </Box>

                  <Box mt={"20px"} mb={"15px"}>
                    {isEditing && editingPillId === index.id ? (
                      <Input
                        value={tempTitle}
                        onChange={(e) => setTempTitle(e.target.value)}
                      />
                    ) : (
                      <Text fontSize={"24px"}>{index.learning_pills}</Text>
                    )}
                  </Box>

                  <Box mb={"15px"}>
                    {isEditing && editingPillId === index.id ? (
                      <>
                        <Text>Selecione a Categoria:</Text>
                        <Select
                          defaultValue={tempCategory || ""}
                          onChange={handleCategoryUpdate}
                          placeholder="Selecione a categoria"
                        >{
                          categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.category}
                            </option>
                          ))}
                        </Select>

                        <Box mt={"20px"}>
                          <Text>Selecione as categorias secundárias:</Text>
                          <ReactSelect
                            id="categoryReactSelect"
                            defaultValue={tempCategories.map(tag => ({
                              value: tag.category_id,
                              label: tag.category_name,
                            }))}
                            isMulti
                            options={options}
                            onChange={handleChangeSecondaryCategoriesUpdate}
                            styles={customStyles}
                          />
                        </Box>
                        <Box mt={"20px"}>
                          <Text>Selecione as Subcategorias:</Text>
                          <ReactSelect
                          key={tempCategory} 
                            options={
                              tempCategory
                                ? categories
                                    .find((cat) => cat.id === tempCategory)
                                    ?.subcategories_continuing_learning.map(
                                      (sub) => ({
                                        value: sub.id,
                                        label: sub.name,
                                      })
                                    ) || []
                                : []
                            }
                            value={
                              selectedSubcategories?.[0] ||
                              selectedSubcategories ||
                              null
                            }
                            onChange={handleSubcategoriesUpdate}
                            placeholder={
                              !tempCategory
                                ? "Selecione uma categoria primeiro"
                                : "Selecione uma Subcategoria"
                            }
                            isDisabled={!tempCategory}
                            styles={customStyles}
                            isClearable
                          />
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box>
                    {isEditing && editingPillId === index.id ? (
                      <JoditEditor
                        value={tempDescription}
                        onChange={(value) => setTempDescription(value)}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: index.description,
                        }}
                      />
                    )}
                  </Box>

                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
