import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { LuDownloadCloud } from "react-icons/lu";
import { environment } from "../../environment";

export default function Budgets() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listBudgetsApi, approveBudgetApi } = useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [budgets, setBudgets] = useState([]);
  const [budgetPreview, setBudgetPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);

  async function loadBudgets(page) {
    try {
      const budgetsApi = await listBudgetsApi();

      console.log("budgets",budgetsApi?.data);
  
      setBudgets(budgetsApi?.data?.[0] || []);

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadBudgets(currentPage);
  }, [currentPage]);

  function approveBudget(budgetId) {
    setSelectedBudgetId(budgetId);
    onOpen();
  }

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{
          base: 0, // Remove a margem em telas menores (base)
          md: 270, // Define a margem em telas maiores (md)
        }}
        minWidth={"78vw"}
      >
        <Box
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box>
              <Text fontSize={"24px"} fontWeight={"600"}>
                Orçamentos
              </Text>
            </Box>

            <Spacer />

            <>
              <Box>
                <Button
                  colorScheme="green"
                  fontSize={"14px"}
                  _hover={{
                    backgroundColor: "#33BA9D",
                  }}
                >
                  <Link to="/orcamento/criar"> Criar Orçamento </Link>
                </Button>
              </Box>
            </>
          </Flex>
        </Box>

        <Box
          backgroundColor={"white"}
          mt={5}
          boxShadow={"0px 8px 16px -10px"}
          borderRadius={"8px"}
          marginBottom={5}
        >
          <TableContainer>
            <Table fontSize={"12px"}>
              <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                <Tr>
                  <Th>ID</Th>
                  <Th>Titulo</Th>
                  {/* <Th>Status</Th> */}
                  <Th>Data</Th>
                  <Th>Download</Th>
                </Tr>
              </Thead>
              <Tbody color={"#888686"} fontWeight={"300"}>
                {budgets.map((budget) => (
                  <Tr key={budget.id}>
                    <Td>{budget.id}</Td>
                    <Td>{budget.title}</Td>
                    <Td>{format(parseISO(budget.created_at), "dd/MM/yyyy")}</Td>
                    {/* <Td>{budget.status === 0 ? "Não Aprovado" : "Aprovado"}</Td> */}
                    <Td>
                      <button
                        onClick={() => {
                          const url = `${environment.baseURL}/storage/budgets/${budget.pdf_path}`;
                          window.open(url, "_blank");
                        }}
                        style={{
                          background: "black",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <LuDownloadCloud
                          color="white"
                          fontSize={"24px"}
                          cursor={"pointer"}
                        />
                      </button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>

            </Table>
          </TableContainer>
        </Box>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Aprovação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Você quer mesmo aprovar este orçamento?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                approveBudgetApi(selectedBudgetId);
                onClose();
              }}
            >
              Sim
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
